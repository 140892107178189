.filter {

  &__header {

  }

  &__title {
    @media #{$breakpoint-mobile} {
      margin-bottom: 0;
    }
    @media #{$breakpoint-md} {
      float: left;
    }
  }

  &__wrap {
    position: relative;
    padding-top: 39px;
    padding-bottom: 40px;
    @media #{$breakpoint-md} {
      padding-top: 27px;
      padding-bottom: 0;
    }
    @media #{$breakpoint-lg} {
      padding-top: 27px;
    }

    @media #{$breakpoint-xl} {
      padding-top: 33px;
    }

    select {
      width: 100%;
    }
    @media #{$breakpoint-md} {
      float: right;
      select {
        width: 180px;
      }
    }
  }

}


//
// Select Appearance
//

.ui-selectmenu-menu {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: none;
  border: 1px solid $btn-border;
  border-top: 0;
  background-color: $white;
  box-sizing: border-box;
  z-index: 10;
  padding-bottom: 5px;
}
.ui-selectmenu-menu .ui-menu {
  overflow: auto;
  /* Support: IE7 */
  overflow-x: hidden;
  padding-bottom: 1px;
  margin-top: -4px;
  margin-bottom: 0;

  li {
    font-size: $btn-font-size;
    color: $btn-color;
    font-weight: $btn-font-weight;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 15px 5px 15px;
    cursor: pointer;
    outline: 0;

    // Hide selected item in dropdown
    &.ui-state-focus {
      color: $blue-pastel;
    }
  }
}
.ui-selectmenu-open {
  display: block;
  // Hack to hide border for design purposes.
  &:before {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    left: 0;
    top: -1px;
    background-color: $white;
  }
}
.ui-selectmenu-button {
  width: 100%;
  height: $btn-height;
  display: block;
  outline: 0;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid $btn-border;
  background: $white;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  @media #{$breakpoint-md} {
    max-width: 180px;
  }
  &[aria-expanded="true"] {
    span.ui-icon {
      transform: rotate(-180deg);
      margin-top: -4px;
    }
  }
}
.ui-selectmenu-button span.ui-icon {
  right: 13px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 15px;
  height: 9px;
  background: url(../images/icons/select-arrow.svg) no-repeat;
  background-size: 15px 9px;
}
.ui-selectmenu-button span.ui-selectmenu-text {
  text-align: left;
  padding: 0 35px 0 15px;
  display: block;
  line-height: $btn-height;;
  font-size: $btn-font-size;
  color: $btn-color;
  font-weight: $btn-font-weight;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
