ul {
  padding: 0;
  margin: 0 0 20px;
}

.list {
  padding-left: 35px;

  li {
    position: relative;
  }

  &--inline {
    padding-left: 0;
    li {
      display: inline;
      letter-spacing: $letter-spacing-25;
    }
  }

  &--comma {
    padding-left: 0;
    list-style: none;

    li {
      &:after {
        content: ",";
        display: inline;
      }
      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }
  }

  &--sidebar-split {
    @media #{$breakpoint-md} {
      width: 66.66%;
    }
    @media #{$breakpoint-lg} {
      width: 100%;
    }
    li {
      margin-bottom: 6px;
      @media #{$breakpoint-md} {
        width: 50%;
        float: left;
      }
      @media #{$breakpoint-lg} {
        width: 100%;
        margin-bottom: 12px;
        float: none;
      }
    }
  }

  &--sidebar {
    li {
      margin-bottom: 6px;
      @media #{$breakpoint-lg} {
        margin-bottom: 12px;
      }
    }
  }

  &--tags {
    a {
      color: $blue-pastel;
      transition: color 0.2s ease-in-out;
      line-height: 2;
      &:hover {
        color: $dark;
      }
    }
  }

  &--bookshelf {
    li {
      margin-bottom: 18px;
      font-size: 12px;
      line-height: 1.4;
      @media #{$breakpoint-lg} {
        font-size: 17px;
      }
      span {
        font-family: $georgia;
        font-style: italic;
        color: rgba($dark, 0.4);
        display: block;
        font-size: 12px;
        @media #{$breakpoint-lg} {
          font-size: 16px;
        }
      }
    }
  }


  //
  // Client List
  //

  &--clients {
    list-style: none;
    margin-bottom: 8px;

    @media #{$breakpoint-md} {
      padding-left: 18px;
      padding-right: 18px;
      padding-bottom: 15px; // bit of spacing for the page design to work
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
    }
    @media #{$breakpoint-lg} {
      margin-left: -$space-1;
      margin-right: -$space-1;
      padding-left: 0;
      padding-right: 0;
    }

    li {
      width: 100%;
      font-size: 14px;
      text-transform: uppercase;
      color: $dark;
      margin-bottom: 11px;
      line-height: 2.5;

      @media #{$breakpoint-md} {
        width: 50%;
        margin-bottom: 16px;
        padding-left: $space-1;
        padding-right: $space-1;
      }

      @media #{$breakpoint-lg} {
        width: 33.33%;
      }

      a {
        color: inherit;
        position: relative;
        padding-bottom: 8px;
        line-height: 2.5;
        border-bottom: 1px solid $blue-pastel;

        // &:after {
        //   content: "";
        //   position: absolute;
        //   bottom: -8px;
        //   left: 0;
        //   width: 100%;
        //   height: 1px;
        //   background: $blue-pastel;
        // }

        &:hover {
          color: $blue-pastel;
        }
      }

    }

  }

  //
  // Tags
  //

  &--tags {
    li {
      color: $tag-grey;
      letter-spacing: $letter-spacing-10;
      font-family: $georgia;
      font-style: italic;
      font-size: 12px;

      &:first-child {
        &:after {
          display: none;
        }
      }

      &:after {
        color: $blue-pastel;
      }

      a {
        color: $blue-pastel;
        letter-spacing: $letter-spacing-10;
        &:hover {
          color: $dark;
        }
      }
    }
  }

}
