input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea {

  &:focus {

  }
}


//
// Input
//

.input {
  padding: 0 0 2px;
  outline: 0;
  display: inline-block;
  border: 0;
  border-bottom: 1px solid $border-light;
  font-size: 12px;
  color: $dark;
  transition: 0.3s ease-in-out;
  height: 40px;

  &.fit {
    width: 100%;
    display: block;
  }

  &:focus {
    border-color: $blue-pastel;
  }
  &:error, &.error {
    border-color: $red;
  }

  &[readonly] {
    cursor: text;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &--search {
    font-family: $condensed;
    color: $dark;
    font-size: 28px;
    line-height: 1;
    height: auto;
    text-transform: uppercase;
    padding-bottom: 9px;
    letter-spacing: $letter-spacing-50;

    @media #{$breakpoint-md} {
      font-size: 45px;
      padding-bottom: 2px;
    }
    @media #{$breakpoint-lg} {
      font-size: 45px;
    }

    // Placeholders
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $dark;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $dark;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $dark;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $dark;
    }

    &:focus {
      // Placeholders
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $grey;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: $grey;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: $grey;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: $grey;
      }
    }
  }

}


//
// Submit
//


//
// Form
//

.form {
  &--copy {
    .input {
      margin-bottom: 20px;
    }

    @media #{$breakpoint-md} {
      display: flex;
      .input {
        margin-bottom: 0;
        margin-right: 20px;
      }
    }
  }
}

//
// Form Row
//

.form-row {
  position: relative;
}



//
// Button Group
//

.btn-group {

  &__wrap {
    text-align: center;
    @media #{$breakpoint-lg} {
      text-align: left;
    }
  }

  &--stacked {

    .btn {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      @media #{$breakpoint-md} {
        margin-right: 20px;
        margin-bottom: 0;
        &:last-child {
          margin-right: 0;
        }
      }
      @media #{$breakpoint-lg} {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }

}
