.accordion {
  position: relative;

  // Top Level of accordion
  &__top {
    // height: 225px;
    cursor: pointer;
    position: relative;
    padding-top: 38px; // line-height accounted
    padding-bottom: 46px; // line-height accounted

    @media #{$breakpoint-md} {
      padding-top: 56px;
      padding-bottom: 25px;
    }

    @media #{$breakpoint-xl} {
      padding-top: 56px;
      padding-bottom: 27px;
    }
  }

  // Accordion logo
  &__logo {
    margin-bottom: 14px;

    svg {
      width: auto;
      height: 26px;
      margin-left: -5px;
    }

    @media #{$breakpoint-md} {
      margin-bottom: 17px;
      svg {
        height: 38px;
      }
    }
    @media #{$breakpoint-xl} {
      margin-bottom: 15px;
      svg {
        height: 38px; // this is to match the design side by side.
      }
    }

  }

  // Accordion excerpt
  &__excerpt {
    font-family: $georgia;
    font-style: italic;
    font-size: 16px;
    line-height: 1.5;

    @media #{$breakpoint-xl} {
      font-size: 18px;
    }
  }

  // Arrow
  &__arrow {
    position: absolute;
    background: 0;
    border: 0;
    padding: 0;
    outline: 0;
    display: block;
    width: 14px;
    height: 8px;
    z-index: 20;

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }

    @media #{$breakpoint-md} {
      width: 20px;
      height: 10px;
      top: 52px;
      right: 0px;
      bottom: auto;
      left: auto;
      transform: none;
    }

    @media #{$breakpoint-xl} {
      right: calc((1/12 * 100%) + 20px);
    }

    &--desktop {
      display: none;
      @media #{$breakpoint-md} {
        display: block;
      }
    }

    &--mobile {
      bottom: 43px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 100;
      @media #{$breakpoint-md} {
        display: none;
      }
    }
  }

  &--open {
    .accordion__arrow {
      transform: translateX(-50%) rotate(-180deg);
      svg {
        polygon {
          fill: $white;
        }
      }
      @media #{$breakpoint-md} {
        transform: rotate(-180deg);
      }
    }
    .accordion__content {
      // Negative spacing to align to psd..
      margin-top: -35px;
      @media #{$breakpoint-md} {
        margin-top: -16px;
      }
      @media #{$breakpoint-xl} {
        margin-top: -7px;
      }
    }
    .accordion__excerpt {
      color: $white;
    }
    .accordion__logo {
      svg {
        path, polygon, rect {
          fill: $white;
        }
      }
    }
  }

  // Content (hidden by default)
  &__content {
    display: none;
    color: $white;
    padding-bottom: 77px;
    @media #{$breakpoint-md} {
      padding-bottom: 57px;
    }
    @media #{$breakpoint-xl} {
      padding-bottom: 45px;
    }

    p {
      line-height: 1.5;
    }

    // Case Studies

    &__studies {
      padding-top: 10px;
      @media #{$breakpoint-xl} {
        padding-top: 17px;
      }

      h6 {
        font-family: $georgia;
        font-style: italic;
        font-size: 24px;
        margin-bottom: 15px;
        @media #{$breakpoint-md} {
          margin-bottom: 14px;
        }
        @media #{$breakpoint-xl} {
          margin-bottom: 17px;
        }
      }

      > a {
        display: block;
        font-size: 12px;
        letter-spacing: $letter-spacing-10;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &__title {
        text-transform: uppercase;
        display: block;
        font-weight: 500;
      }

      &__excerpt {
        font-size: 16px;
        line-height: 1.5;
        font-family: $georgia;
        font-style: italic;
      }
    }

  }


  &--blue {
    .accordion__arrow {
      svg {
        fill: $blue;
        polygon {
          fill: $blue;
        }
      }
    }
    &.accordion--open {
      svg {
        polygon {
          fill: $white;
        }
      }
    }
    &:hover,
    &.accordion--open,
    .accordion__content {
      .accordion__top,
      .accordion__content {
        background: $blue;
      }
    }
  }

  &--purple {
    .accordion__arrow {
      svg {
        fill: $purple;
        polygon {
          fill: $purple;
        }
      }
    }
    &.accordion--open {
      svg {
        polygon {
          fill: $white;
        }
      }
    }
    &:hover,
    &.accordion--open {
      .accordion__top,
      .accordion__content {
        background: $purple;
      }
    }
  }

  &--green {
    .accordion__arrow {
      svg {
        fill: $green;
        polygon {
          fill: $green;
        }
      }
    }
    &.accordion--open {
      svg {
        polygon {
          fill: $white;
        }
      }
    }
    &:hover,
    &.accordion--open {
      .accordion__top,
      .accordion__content {
        background: $green;
      }
    }
  }

  &--orange {
    .accordion__arrow {
      svg {
        fill: $orange;
        polygon {
          fill: $orange;
        }
      }
    }
    &.accordion--open {
      svg {
        polygon {
          fill: $white;
        }
      }
    }
    &:hover,
    &.accordion--open {
      .accordion__top,
      .accordion__content {
        background: $orange;
      }
    }
  }


  //
  // Hover
  //

  &:hover {
    .accordion {
      color: $white;
      &__excerpt {
        color: $white;
      }
      &__arrow {
        svg {
          polygon {
            fill: $white;
          }
        }
      }
      &__logo {
        svg {
          path, polygon, rect {
            fill: $white;
          }
        }
      }
    }
  }



}
