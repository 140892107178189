//
// Thinking Index
//

.page--thinking-index {

  // Hero

  .hero--constrained {
    @media #{$breakpoint-md} {
      margin-bottom: 61px;
    }
    @media #{$breakpoint-lg} {
      margin-bottom: 79px;
    }
  }

  // Section

  .section { // All different to defaults..
    padding-top: 30px;
    padding-bottom: 65px;

    .btn--load-more {
      margin-top: 46px;
    }

    @media #{$breakpoint-md} {
      padding-top: 50px;

      .btn--load-more {
        margin-top: 61px;
      }
    }

    @media #{$breakpoint-lg} {
      padding-top: 68px;
      padding-bottom: 119px;

      .btn--load-more {
        margin-top: 68px;
      }
    }
  }

  // Article Grid

  .article-grid--rows {
    margin-bottom: 0;
    @media #{$breakpoint-md} {
      margin-bottom: 4px;
    }
    @media #{$breakpoint-lg} {
      margin-bottom: 42px;
    }
  }

  .article-grid__load {
    margin-bottom: 4px;
    padding-left: 0;
    padding-right: 0;
  }

}

//
// Thinking General
//

.page--thinking {
  .section { // All different to defaults..
    padding-top: 30px;
    padding-bottom: 65px;

    .btn--load-more {
      margin-top: 46px;
    }

    @media #{$breakpoint-md} {
      padding-top: 50px;

      .btn--load-more {
        margin-top: 61px;
      }
    }

    @media #{$breakpoint-lg} {
      padding-top: 68px;
      padding-bottom: 119px;

      .btn--load-more {
        margin-top: 68px;
      }
    }
  }
}

//
// Thinking detail
//

.page--thinking-detail {
  .section { // All different to defaults..
    padding-top: 28px;
    padding-bottom: 10px;

    .btn--load-more {
      margin-top: 40px;
      margin-bottom: 58px;
    }

    @media #{$breakpoint-md} {
      padding-top: 21px;

      .btn--load-more {
        margin-top: 61px;
        margin-bottom: 109px;
      }

      .btn--back {
        margin-bottom: 8px;
      }
    }

    @media #{$breakpoint-lg} {
      padding-top: 43px;
      padding-bottom: 31px;

      .btn--load-more {
        margin-top: 71px;
        margin-bottom: 89px;
      }
    }

    .list {
      padding-left: 0;
      margin-bottom: 30px;

      @media #{$breakpoint-lg} {
        margin-bottom: 24px;
      }
    }

  }

  .col--side {
    margin-top: 13px;

    @media #{$breakpoint-md} {
      margin-top: 31px;
    }

    @media #{$breakpoint-lg} {
      margin-top: 7px;
    }

    .sidebar__row {
      padding-top: 40px;
      border-bottom: 0;

      @media #{$breakpoint-md} {
        padding-top: 61px;
        padding-bottom: 37px;
      }

      @media #{$breakpoint-lg} {
        padding: 0;
      }
    }
  }

  .expert__wrap {
    @media #{$breakpoint-md} {
      margin-bottom: 17px;
    }
  }

  .section--map {
    @media #{$breakpoint-lg} {
      padding-top: 20px;
    }
  }

  // .section-heading {
  //   @media #{$breakpoint-lg} {
  //     margin-bottom: 50px;
  //   }
  // }

}
