.search {

  &__submit {
    width: 40px;
    height: 40px;
    border: 0;
    outline: 0;
    background: transparent;
    padding: 0;
    position: relative;

    svg {
      width: 20px;
      height: 21px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: rgba($dark, 0.4);

      @media #{$breakpoint-md} {
        width: 32px;
        height: 33px;
      }

      &:hover, &:focus {
        fill: $blue-pastel;
      }
    }
  }

  &__wrap {
    .search__submit {
      position: absolute;
      right: 0;
      top: -7px;

      @media #{$breakpoint-md} {
        top: 1px;
        right: -5px;
      }
    }

    .input--search {
      padding-right: 40px;

      @media #{$breakpoint-md} {
        padding-right: 50px;
      }
    }
  }

  &__results {
    padding-top: 57px; // line height accounted.
    padding-bottom: 60px;

    @media #{$breakpoint-md} {
      padding-top: 89px;
      padding-bottom: 120px;
    }

    .no-results {
      font-size: 28px;
      letter-spacing: $letter-spacing-50;
      font-family: $condensed;
      line-height: 1.2;
      text-transform: uppercase;

      @media #{$breakpoint-md} {
        font-size: 38px;
      }
    }

    .btn--load-more {
      margin-top: 39px;

      @media #{$breakpoint-md} {
        margin-top: 60px;
      }
    }

  }

}
