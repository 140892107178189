.action {

  &__wrap {
    background-color: $grey;
    text-align: center;
    padding: 62px 0 66px;
    @media #{$breakpoint-md} {
      padding: 70px 0 61px;
    }
  }

  &__title {
    font-family: $condensed;
    text-transform: uppercase;
    color: $dark;
    font-size: 28px;
    margin-bottom: 21px;
    @media #{$breakpoint-md} {
      font-size: 45px;
      margin-bottom: 11px;
    }
  }

  &__description {
    font-family: $georgia;
    font-style: italic;
    color: rgba($dark, 0.5);
    font-size: 16px;
    line-height: 1.5;
    @media #{$breakpoint-md} {
      font-size: 18px;
      line-height: 1.7;
    }
  }

  &__button {
    padding-top: 28px;
  }

}
