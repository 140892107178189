// Currently on the about page.

.section {
  padding-top: 56px; // line height accounted.
  padding-bottom: 25px; // line height adjusted

  @media #{$breakpoint-md} {
    padding-top: 72px;
    padding-bottom: 46px;
    &--top {
      padding-top: 59px; // still different on other pages (e.g. people-detail, careers)
    }
  }

  @media #{$breakpoint-xl} {
    padding-top: 80px;
    // padding-top: 74px; // Old value - advised to make global 80px
    padding-bottom: 48px;
  }

  //
  // Section with accordion - about.html
  //

  &--accordion {
    padding-top: 60px;
    padding-bottom: 58px;

    @media #{$breakpoint-md} {
      padding-top: 83px;
    }

    @media #{$breakpoint-xl} {
      padding-top: 78px;
      padding-bottom: 62px;
    }
  }

  //
  // Related work posts section
  //

  &--related-work {
    padding-top: 34px;
    padding-bottom: 25px; // line height adjusted

    @media #{$breakpoint-md} {
      padding-top: 38px;
      padding-bottom: 46px;
    }

    @media #{$breakpoint-xl} {
      padding-top: 74px;
      padding-bottom: 48px;
    }
    header {
      .section-heading {
        @media #{$breakpoint-md} {
          float: left;
        }
      }
      .btn {
        margin-bottom: 40px;
        @media #{$breakpoint-md} {
          float: right;
          margin-top: 36px; // To align with bottom line of title
          margin-bottom: 0;
        }
        @media #{$breakpoint-lg} {
          margin-top: 42px;
        }
      }
    }
    .section-heading {
      margin-top: 9px;
      margin-bottom: 40px;
      @media #{$breakpoint-md} {
        margin-bottom: 54px;
      }
      @media #{$breakpoint-xl} {
        margin-bottom: 44px;
      }
    }
    // For appearance at mobile without margins
    .related-work__wrap {
      margin-left: -$space-2;
      margin-right: -$space-2;
      // For general appearance for the grid
      @media #{$breakpoint-md} {
        margin-left: -$space-1;
        margin-right: -$space-1;
        .col {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    .article-preview {
      margin-bottom: $space-2;
      @media #{$breakpoint-md} {
        margin-bottom: 0;
      }
    }
  }

  //
  // Expertise Section when needed
  //

  &--expertise {
    padding-top: 46px;
    padding-bottom: 10px;

    @media #{$breakpoint-md} {
      padding-top: 49px;
      padding-bottom: 43px;
    }
    @media #{$breakpoint-xl} {
      padding-top: 100px;
      padding-bottom: 0;
    }
    &.expertise-block {
      padding-top: 46px;
      padding-bottom: 10px;

      @media #{$breakpoint-md} {
        padding-top: 68px;
        padding-bottom: 43px;
      }
      @media #{$breakpoint-xl} {
        padding-top: 100px;
        padding-bottom: 0;
      }
    }
  }

  //
  // Cancel top padding from sections
  //

  &--pt0 {
    padding-top: 0;
  }


  //
  // Cancel bottom padding from sections
  //

  &--pb0 {
    padding-bottom: 0;
  }

  //
  // UL font size bump inside sections
  //

  .list {
    font-size: 14px;
    padding-left: 15px;
    li {
      margin-bottom: 3px;
    }
    @media #{$breakpoint-md} {
      font-size: 16px;
      padding-left: 35px;
      li {
        margin-bottom: 0;
      }
    }
  }

  //
  // Article list section
  //

  &--article-list {
    padding: 0 0 70px;

    @media #{$breakpoint-md} {
      padding: 0 0 119px;
    }

    @media #{$breakpoint-lg} {
      padding: 0 0 122px;
    }
  }

  &--map {
    @media #{$breakpoint-lg} {
      padding-top: 20px;
    }
  }

}
