.page--about {
  .site-content {
    background: $grey;
  }
}

.page--about-detail {
  .expertise__wrap {
    @media #{$breakpoint-md} {
      padding-left: 0; // design for this page doesn't have the padding.
      padding-right: 0;
    }
  }
}
