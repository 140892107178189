.site-footer {
  padding: 53px 0 28px;
  width: 100%;
  background-color: $site-footer-bg;
  color: $white;
  font-size: $footer-font-size-mobile;
  text-align: center;
  height: $footer-height-mobile;
  position: relative;
  left: 0;

  @media #{$breakpoint-md} {
    height: $footer-height-tablet;
    font-size: $footer-font-size-tablet;
    padding: 58px 0 27px;
  }
  @media #{$breakpoint-xl} {
    font-size: $footer-font-size-desktop;
    padding: 58px 0 27px;
    height: $footer-height-desktop;
  }

  p {
    margin-bottom: 0;
    display: inline-block;
    line-height: 1.5;
    font-size: inherit;
    letter-spacing: $letter-spacing-85;
    @media #{$breakpoint-md} {
      float: left;
    }
  }

  &__meta {
    text-align: center;
    display: inline-block;

    @media #{$breakpoint-mobile} {
      p, .site-footer__nav {
        display: block;
      }
      .site-footer__nav {
        margin-bottom: 28px; // accounting for line-height
        a {
          margin-left: 0;
        }
      }
    }
  }

  &__nav {
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 23px;
    @media #{$breakpoint-md} {
      float: right;
      margin-bottom: 0;
    }
    a {
      margin-left: 25px;
      letter-spacing: $letter-spacing-85;
      @media #{$breakpoint-lg} {
        margin-left: 30px;
      }
    }
  }

  .social-icons {
    margin-bottom: 41px;
    @media #{$breakpoint-md} {
      margin-bottom: 38px;
    }
    @media #{$breakpoint-xl} {
      margin-bottom: 43px;
    }
    svg {
      fill: $white;
    }
  }

}
