* {
  box-sizing: border-box;
}

html {
  font-size: $base-font-size-mobile;
  @media #{$breakpoint-md} {
    font-size: $base-font-size-tablet;
  }
  @media #{$breakpoint-xl} {
    font-size: $base-font-size-desktop;
  }
  letter-spacing: $letter-spacing-25;
  line-height: $base-line-height;
  font-family: $base-font;
  font-weight: 400;
  color: $dark;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

body {
  background: #fff;
  height: 100%;
}

.site-wrap {
  padding-top: $site-header-height-mobile;
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  @media #{$breakpoint-md} {
    padding-top: $site-header-height-tablet;
  }
  @media #{$breakpoint-xl} {
    padding-top: $site-header-height-desktop;
  }
}

.site-content {
  min-height: 100%;
  margin-bottom: -$footer-height-mobile;
  position: relative;

  @media #{$breakpoint-md} {
    margin-bottom: -$footer-height-tablet;
  }
  @media #{$breakpoint-xl} {
    margin-bottom: -$footer-height-desktop;
  }

  &:after {
    content: "";
    display: block;
    margin: 0;
    height: $footer-height-mobile;
    @media #{$breakpoint-md} {
      height: $footer-height-tablet;
    }
    @media #{$breakpoint-xl} {
      height: $footer-height-desktop;
    }
  }
}

.container {
  max-width: $container-max-width + 40; // account for padding
  padding: 0 20px;
  margin: 0 auto;
  position: relative;

  &--indent {
    position: relative;
    @media #{$breakpoint-md} {
      margin-left: 30px;
      margin-right: 30px;
    }
    @media #{$breakpoint-xl} {
      margin-left: calc((1/12 * 100%));
      margin-right: calc((1/12 * 100%));
    }
  }

  &-article {
    @media #{$breakpoint-md} {
      margin-left: 30px;
      margin-right: 30px;
    }
    @media #{$breakpoint-lg} {
      margin-left: auto;
      margin-right: auto;
    }
    .featured-col {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--p0 {
    max-width: $container-max-width;
    padding: 0;
  }

}

//
// Links
//

a {
  text-decoration: none;
  color: inherit;
}



//
// Typography
//

h1, h2, h3,
h4, h5, h6 {
  margin: 0;
  font-weight: 400;
  letter-spacing: $letter-spacing-25;
}

p {
  font-size: 14px;
  margin: 0 0 28px;
  line-height: 1.7;
  @media #{$breakpoint-md} {
    font-size: 16px;
    line-height: 1.5;
  }
  @media #{$breakpoint-xl} {
    line-height: 1.7;
  }
}


//
// Figure
//

figure {
  margin: 0;
}


//
// Show
//

.lg-show {
  display: none;
  @media #{$breakpoint-lg} {
    display: block;
  }
}

.xl-show {
  display: none;
  @media #{$breakpoint-xl} {
    display: block;
  }
}


//
// BG Colours
//

.bg-white {
  background-color: $white;
}


//
// Extend Grid
//

@media #{$breakpoint-xl} {

  .xl-col {
    float: left;
    box-sizing: border-box;
  }

  .xl-col-right {
    float: right;
    box-sizing: border-box;
  }

  .xl-col-1 {
    width: (1/12 * 100%);
  }

  .xl-col-2 {
    width: (2/12 * 100%);
  }

  .xl-col-3 {
    width: (3/12 * 100%);
  }

  .xl-col-4 {
    width: (4/12 * 100%);
  }

  .xl-col-5 {
    width: (5/12 * 100%);
  }

  .xl-col-6 {
    width: (6/12 * 100%);
  }

  .xl-col-7 {
    width: (7/12 * 100%);
  }

  .xl-col-8 {
    width: (8/12 * 100%);
  }

  .xl-col-9 {
    width: (9/12 * 100%);
  }

  .xl-col-10 {
    width: (10/12 * 100%);
  }

  .xl-col-11 {
    width: (11/12 * 100%);
  }

  .xl-col-12 {
    width: 100%;
  }

}

//
// Center Block
//

.center-block {
  margin-left: auto;
  margin-right: auto;
}


//
// Featured col
//

.featured-col {
  @media #{$breakpoint-md} {
    margin-left: 30px;
    margin-right: 30px;
  }
  @media #{$breakpoint-lg} {
    margin-left: auto;
    margin-right: auto;
    padding-left: 25px;
    padding-right: 25px;
    width: 90%;
  }
  @media #{$breakpoint-xl} {
    width: calc(66.66% + 40px);
    padding-left: 25px;
    padding-right: 20px;
  }
}


//
// Blockquote
//

blockquote {
  font-family: $georgia;
  font-style: italic;
  color: $blue;
  margin: 0;
  position: relative;
  padding-top: 30px;
  position: relative;
  margin-bottom: 60px;
  margin-left: 20px;

  &:not(.bq-featured) {
    max-width: 555px; // appears a max width is required.
    @media #{$breakpoint-lg} {
      max-width: 490px;
    }
  }

  @media #{$breakpoint-md} {
    padding-top: 35px;
    margin-bottom: 48px;
    margin-left: 55px;
  }

  @media #{$breakpoint-lg} {
    padding-top: 34px;
    margin-bottom: 50px;
  }

  @media #{$breakpoint-xl} {
    margin-left: 100px;
  }

  &.bq-featured {
    color: $purple;
    margin-left: 20px;
    @media #{$breakpoint-md} {
      width: 85%;
      margin-left: 50px;
    }
    @media #{$breakpoint-lg} {
      width: 100%;
      margin-left: 0;
    }
  }

  &:before {
    content: "";
    background: CurrentColor;
    left: 0;
    position: absolute;
    display: block;
    width: 60px;
    height: 8px;
    top: 4px;

    @media #{$breakpoint-md} {
      top: 10px;
    }

    @media #{$breakpoint-lg} {
      top: 8px;
    }
  }

  .bq-title {
    color: inherit;
    font-size: 18px;
    letter-spacing: $letter-spacing-25;
    line-height: 1.66;

    @media #{$breakpoint-md} {
      font-size: 28px;
      line-height: 1.357;
    }

    &:before {
      content: "“";
      display: inline;
    }

    &:after {
      content: "”";
      display: inline;
    }

  }

  .bq-source {
    color: rgba($dark, 0.4);
    font-size: 12px;
    letter-spacing: $letter-spacing-50;
    display: block;
    margin-top: 10px;

    @media #{$breakpoint-md} {
      font-size: 16px;
    }

    @media #{$breakpoint-lg} {
      margin-top: 34px;
    }

    &:before {
      content: "-";
      display: inline;
      margin-right: 5px;
    }
  }


}
