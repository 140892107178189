.map {
  // In case you want an actual map later on.
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;


  &__wrap {
    position: relative;
    width: 100%;
    padding-bottom: 42.35%;
    background-size: cover;
    background-position: center;

    @media #{$breakpoint-mobile} {
      width: 100vw;
      margin-left: -20px;
      margin-right: -20px;
      height: 310px;
    }
  }

}
