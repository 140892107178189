.result {
  display: block;
  border-bottom: 1px solid $border;
  padding-bottom: 36px;
  padding-top: 57px;

  @media #{$breakpoint-lg} {
    padding-top: 69px;
  }

  &--hidden {
    display: none;
  }

  &:first-of-type {
    padding-top: 0;

    @media #{$breakpoint-md} {
      padding-top: 2px;
    }
  }

  &__title {
    font-family: $condensed;
    color: $dark;
    text-transform: uppercase;
    letter-spacing: $letter-spacing-25;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 2px;

    @media #{$breakpoint-md} {
      font-size: 38px;
      line-height: 1.1;
      margin-bottom: 13px;
    }

    @media #{$breakpoint-lg} {
      width: 80%;
    }
  }

  &__excerpt {
    font-family: $georgia;
    display: block;
    font-style: italic;
    color: rgba($dark, 0.4);
    letter-spacing: $letter-spacing-25;
    font-size: 13px;
    line-height: 22px;

    @media #{$breakpoint-md} {
      font-size: 14px;
    }

    @media #{$breakpoint-lg} {
      width: 80%;
    }
  }

  &:hover {
    border-color: $blue-pastel;
    .result__title {
      color: $blue-pastel;
    }
  }

}
