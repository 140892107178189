.thinking-item {
  background: $grey;
  outline: 0;
  display: block;
  cursor: pointer;

  @media #{$breakpoint-md} {
    display: flex;
    min-height: 230px;
  }

  @media #{$breakpoint-lg} {
    min-height: 386px;
  }

  &__wrap {
    @media #{$breakpoint-mobile} {
      margin-left: -20px;
      margin-right: -20px;
    }

    margin-bottom: 20px;
  }

  &__type {
    font-size: 12px;
    font-weight: 500;
    color: $blue-pastel;
    text-transform: uppercase;
    letter-spacing: $letter-spacing-150;
    display: block;
    margin-bottom: 9px;

    @media #{$breakpoint-md} {
      font-size: 11px;
      margin-bottom: 21px;
    }

    @media #{$breakpoint-lg} {
      font-size: 14px;
      margin-bottom: 28px;
    }

    &:after {
      content: "_";
      display: inline;
    }
  }

  &__title {
    font-size: 22px;
    line-height: 32px;
    font-family: $georgia;
    font-style: italic;
    letter-spacing: $letter-spacing-10;
    margin-bottom: 5px;

    @media #{$breakpoint-md} {
      margin-bottom: 15px;
      line-height: 28px;
      max-width: 420px;
    }

    @media #{$breakpoint-lg} {
      font-size: 33px;
      line-height: 42px;
      margin-bottom: 12px;
      max-width: 680px;
    }
  }

  &__meta {
    font-size: 12px;
    font-weight: 500;
    color: rgba($dark, 0.4);
    text-transform: uppercase;
    letter-spacing: $letter-spacing-10;

    @media #{$breakpoint-md} {
      font-size: 11px;
    }

    @media #{$breakpoint-lg} {
      font-size: 14px;
    }
  }

  &__category {
    font-size: 12px;
    font-family: $georgia;
    font-style: italic;
    letter-spacing: $letter-spacing-10;
    color: $blue-pastel;
    position: absolute;
    left: 28px;
    bottom: 26px;

    @media #{$breakpoint-md} {
      bottom: 25px;
      left: 32px;
      font-size: 11px;
    }

    @media #{$breakpoint-lg} {
      font-size: 12px;
      bottom: 41px;
      left: 45px;
    }
  }

  &__content {
    position: relative;
    @media #{$breakpoint-mobile} {
      min-height: 320px;
      padding: 25px 28px 50px; // 28px to make text fit.
    }
    @media #{$breakpoint-md} {
      order: 2;
      padding: 28px 32px 50px;
    }
    @media #{$breakpoint-lg} {
      padding: 45px 50px 60px;
    }
  }

  &__image {
    @media #{$breakpoint-md} {
      width: 248px;
      order: 1;
      flex-shrink: 0;
    }
    @media #{$breakpoint-lg} {
      width: 400px;
    }
    img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }

  &:hover {
    background: $dark;
    .thinking-item__title,
    .thinking-item__excerpt {
      color: $white;
    }
    .thinking-item__meta {
      color: rgba(#dad9d6, 0.4);
    }
  }

}
