.expertise {
  width: 100%;
  border-bottom: 1px solid $border;
  transition: border 0.3s ease;
  padding-bottom: 10px;
  margin-bottom: 53px; // accounts for line height
  outline: 0;

  @media #{$breakpoint-md} {
    margin-bottom: 76px; // accounts for line height
    padding-bottom: 15px;

    &__wrap {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  @media #{$breakpoint-xl} {
    margin-bottom: 90px;
    &__wrap {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 30px;
    }
  }

  &__header {
    .section-heading {
      float: left;
      margin-bottom: 57px;
      @media #{$breakpoint-md} {
        margin-bottom: 82px;
      }
      @media #{$breakpoint-xl} {
        margin-bottom: 90px;
      }
    }
    .btn {
      float: right;
    }
  }

  h3 {
    font-family: $condensed;
    text-transform: uppercase;
    transition: color 0.3s ease;
    font-size: $expertise-title-size-mobile;
    margin-bottom: 5px;
    line-height: 1.08;

    @media #{$breakpoint-md} {
      font-size: $expertise-title-size-tablet;
      margin-bottom: 0;
    }
    @media #{$breakpoint-lg} {
      font-size: $expertise-title-size-desktop;
      height: 82px;
    }
    @media #{$breakpoint-xl} {
      height: auto;
      margin-bottom: 6px;
    }
  }

  .list--inline {
    font-family: $georgia;
    font-style: italic;
    color: $blue-pastel;
    transition: color 0.3s ease;
    @media #{$breakpoint-xl} {
      padding-right: 20px;
    }
  }

  &:hover {
    .list--inline {
      color: $dark;
    }
  }

  &--blue {
    &:hover {
      h3 {
        color: $blue;
      }
      border-color: $blue;
    }
  }

  &--orange {
    &:hover {
      h3 {
        color: $orange;
      }
      border-color: $orange;
    }
  }

  &--purple {
    &:hover {
      h3 {
        color: $purple;
      }
      border-color: $purple;
    }
  }

  &--green {
    &:hover {
      h3 {
        color: $green;
      }
      border-color: $green;
    }
  }

}
