.site-header {
  width: 100%;
  // width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  z-index: $site-header-z;
  height: $site-header-height-mobile;
  backface-visibility: hidden;
  @media #{$breakpoint-md} {
    height: $site-header-height-tablet;
  }
  @media #{$breakpoint-xl} {
    height: $site-header-height-desktop;
  }

  &.site-header--bg {
    background-color: $site-header-bg;
  }

  .container, .clearfix {
    height: 100%;
  }

  .site-nav {
    display: inline-block;
    padding-top: 50px;
  }

  .site-logo {
    width: 160px; // have to account for padding on the asset.
    height: auto;
    display: none;
    @media #{$breakpoint-xl} {
      display: block;
    }
    > svg, > img {
      width: 100%;
    }
    > img {
      height: auto;
    }
    &__wrap {
      // display: flex; // in an ideal world you use this for vertical centering any logo
      // align-items: center;
      height: 100%;
      // this is to perfectly align to the PSD. not required if you use the above method
      padding-top: 28px;
      @media #{$breakpoint-md} {
        padding-top: 25px;
      }
      @media #{$breakpoint-xl} {
        padding-top: 28px;
      }
    }

    &--mobile {
      width: 180px;
      display: block;
      @media #{$breakpoint-md} {
        display: none;
      }
      @media #{$breakpoint-xl} {
        display: none;
      }
    }

    &--tablet {
      width: 216px;
      @media #{$breakpoint-mobile} {
        display: none;
      }
      @media #{$breakpoint-xl} {
        display: none;
      }
      display: block;
    }
  }

  .mobile-nav-toggle {
    margin-top: 25px;
  }

  .search-button {
    position: relative;
    top: 3px;
  }

  .lang-select {
    position: absolute;
    right: 20px;
    top: 10px;
    display: none;
    @media #{$breakpoint-xl} {
      display: block;
    }

  }

}


