//
// Career Page's
//

.page--careers {
  .hero {
    margin-bottom: 0;
  }
  .hero__title {
    line-height: 1.16;
    @media #{$breakpoint-md} {
      font-size: 62px; // different to default ?? (careers-index)
      line-height: 1.18;
    }
    @media #{$breakpoint-xl} {
      font-size: $hero-title-size-desktop;
      line-height: 1.01;
    }
  }
  .section--top {
    padding-top: 40px;
    padding-bottom: 70px;
    @media #{$breakpoint-md} {
      padding-top: 60px;
      padding-bottom: 40px;
    }
    @media #{$breakpoint-lg} {
      padding-top: 80px;
      padding-bottom: 75px;
    }
  }
}



//
// Career Grid
//

.career {

  &__grid {
    list-style: none;
    margin-left: -20px;
    margin-right: -20px;

    @media #{$breakpoint-md} {
      margin-left: -10px;
      margin-right: -10px;
      margin-bottom: 40px;
    }

    @media #{$breakpoint-lg} {
      margin-bottom: 50px;
    }

    li {
      float: left;
      padding: 0 0 20px;
      width: 100%;
      @media #{$breakpoint-md} {
        width: 50%;
        padding: 0 10px 20px;
      }
      @media #{$breakpoint-lg} {
        width: 33.33%;
      }
    }
  }

  &__item {
    display: block;
    background: $grey;
    height: 206px;
    position: relative;
    transition: background 0.2s ease-in-out;
    padding-left: 20px;
    padding-right: 20px;

    @media #{$breakpoint-md} {
      height: 205px;
      padding-left: 30px;
      padding-right: 30px;
    }

    @media #{$breakpoint-lg} {
      height: 220px;
    }

    &:hover {
      background: $dark;

      .career__content {
        p {
          color: $white;
        }
      }
    }
  }

  &__content {
    text-align: center;
    position: relative;
    display: block;
    top: 50%;
    transform: translateY(-50%);

    p {
      color: $dark;
      font-family: $georgia;
      font-style: italic;
      font-size: 28px;
      line-height: 1.28;
      transition: color 0.2s ease-in-out;
      letter-spacing: $letter-spacing-50;

      span {
        display: block;
        color: $blue-pastel;
        font-size: 16px;
        letter-spacing: $letter-spacing-25;
        padding-top: 13px;
      }
    }
  }

  &__block {
    padding-bottom: 50px;
    @media #{$breakpoint-md} {
      padding-bottom: 70px;
    }
    @media #{$breakpoint-lg} {
      padding-bottom: 105px;
    }
  }

  &__stamp {
    font-size: 13px;
    font-weight: 500;
    color: rgba($dark, 0.4);
    position: absolute;
    top: 20px - 2;
    right: 20px;
    letter-spacing: $letter-spacing-150;

    @media #{$breakpoint-md} {
      top: 30px - 2;
      right: 30px;
    }

    @media #{$breakpoint-lg} {
      top: 33px - 2;
      right: 33px;
    }
  }

}
