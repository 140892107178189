.hero {
  position: relative;
  color: $white;

  &__bg {
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(#302e2b, 0.75);
    background-blend-mode: multiply; // Won't work in IE.
    background-position: center;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url('../images/overlay-lines@2x.png');
      background-repeat: repeat;
      background-size: 5px 5px;
      @include IE {
        // Add BG to :after for IE to have some form of overlay.
        background-color: rgba(#302e2b, 0.75);
      }
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    padding-top: 158px;
    padding-bottom: 3px;
    @media #{$breakpoint-md} {
      padding-top: 373px;
      padding-bottom: 85px;
    }
    @media #{$breakpoint-xl} {
      padding-top: 336px;
      padding-bottom: 111px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  &__title {
    letter-spacing: $letter-spacing-50;
    text-transform: uppercase;
    color: inherit;
    font-family: $condensed;
    line-height: $hero-title-lh;
    font-size: $hero-title-size-mobile;
    margin-bottom: 12px;
    @media #{$breakpoint-md} {
      font-size: $hero-title-size-tablet;
      margin-bottom: 8px;
    }
    @media #{$breakpoint-xl} {
      font-size: $hero-title-size-desktop;
      margin-bottom: 6px; // desktop margin bottom accounts for line height.
    }


    // Artificial Underscore
    &:after {
      content: "";
      width: 30px;
      height: 4px;
      background: $white;
      margin-left: 5px;
      display: inline-block;

      @media #{$breakpoint-md} {
        width: 48px;
        height: 6px;
      }
      @media #{$breakpoint-xl} {
        width: 60px;
        height: 8px;
      }
    }

  }

  &__subtitle {
    font-family: $georgia;
    font-style: italic;
    letter-spacing: $letter-spacing-50;
    line-height: $hero-subtitle-lh-mobile;
    font-size: $hero-subtitle-size-mobile;
    @media #{$breakpoint-md} {
      line-height: $hero-subtitle-lh-tablet;
      font-size: $hero-subtitle-size-tablet;
    }
    @media #{$breakpoint-xl} {
      line-height: $hero-subtitle-lh-desktop;
      font-size: $hero-subtitle-size-desktop;
    }
  }


  // No content - page list -> about-detail, work-detail
  &--plain {
    height: 180px;
    @media #{$breakpoint-md} {
      height: 428px;
    }
    @media #{$breakpoint-xl} {
      height: 490px;
    }

    .hero__bg {
      background-blend-mode: normal;
    }

  }

}


//
// Hero Constrained
//

.hero--constrained {
  height: $hero-min-height-mobile;
  margin-bottom: $hero-mb-mobile;
  @media #{$breakpoint-md} {
    height: $hero-min-height-tablet;
    margin-bottom: $hero-mb-tablet;
  }
  @media #{$breakpoint-xl} {
    height: $hero-min-height-desktop;
    margin-bottom: $hero-mb-desktop;
  }

  .hero__content {
    padding-bottom: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  // Title margin is different on constrained layout according to design..
  .hero__title {
    margin-bottom: 15px;
    @media #{$breakpoint-xl} {
      margin-bottom: 6px;
    }
  }

}

.hero--vertical-center {
  .hero__content {
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .hero__title {
    @media #{$breakpoint-md} {
      font-size: 62px; // different to default ?? (careers-index)
    }
    @media #{$breakpoint-xl} {
      font-size: $hero-title-size-desktop;
    }
  }
}


//
// No Subtitle
//


.hero--no-subtitle {
  .hero__title {
    margin-bottom: 0;
  }
}
