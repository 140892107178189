.expert {
  width: 100%;

  @media #{$breakpoint-md} {
    display: flex;
  }

  figure {
    position: relative;
    @media #{$breakpoint-md} {
      overflow: hidden;
    }

    @media #{$breakpoint-mobile} {
      margin-left: -20px;
      margin-right: -20px;
    }

    @media #{$breakpoint-md} {
      width: 31.6%;
      margin-right: $space-2;
    }

    @media #{$breakpoint-lg} {
      width: 32%;
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  &__content {
    background: $grey;
    position: relative;
    padding: 24px 29px 65px;

    @media #{$breakpoint-mobile} {
      margin-left: -20px;
      margin-right: -20px;
      min-height: 350px;
    }

    @media #{$breakpoint-md} {
      padding: 22px 29px 50px;
      flex: 1;
    }

    @media #{$breakpoint-lg} {
      padding: 42px 50px 70px;
    }

    footer {
      position: absolute;
      left: 29px;
      right: 29px;
      bottom: 25px;
      @media #{$breakpoint-md} {
        bottom: 15px;
      }
      @media #{$breakpoint-lg} {
        left: 50px;
        right: 50px;
        bottom: 40px;
      }
    }

  }

  &__name {
    font-family: $georgia;
    font-style: italic;
    letter-spacing: $letter-spacing-10;
    font-size: 22px;
    margin-bottom: 6px;

    @media #{$breakpoint-md} {
      font-size: 18px;
      margin-bottom: 5px;
    }

    @media #{$breakpoint-lg} {
      font-size: 24px;
      margin-bottom: 12px;
    }
  }

  &__role {
    font-weight: 500;
    color: rgba($dark, 0.4);
    text-transform: uppercase;
    letter-spacing: $letter-spacing-10;
    font-size: 12px;
    margin-bottom: 25px;

    @media #{$breakpoint-md} {
      font-size: 10px;
      margin-bottom: 16px;
    }

    @media #{$breakpoint-lg} {
      font-size: 14px;
      margin-bottom: 28px;
    }
  }

  &__caption {
    font-family: $georgia;
    font-style: italic;
    font-size: 12px;
    margin-bottom: 0;
    line-height: 1.66;

    @media #{$breakpoint-md} {
      line-height: 1.75;
    }

    @media #{$breakpoint-lg} {
      font-size: 14px;
    }

    @media #{$breakpoint-xl} {
      font-size: 16px;
    }
  }

  .text-button {
    float: left;
    font-size: 12px;
    padding-top: 5px;

    @media #{$breakpoint-md} {
      font-size: 10px;
      padding-top: 6px;
    }

    @media #{$breakpoint-lg} {
      font-size: 14px;
      padding-top: 4px;
    }
  }
  .social-icons {
    float: right;
    a {
      opacity: 0.6;
    }
  }

  .social-icons {
    margin-bottom: 0;
  }

  &__wrap {
    .section-heading {
      @media #{$breakpoint-md} {
        margin-bottom: 45px;
      }
    }
  }

}



//
// Expert Small (extension of expert class)
//

.expert--dual {
  @media #{$breakpoint-lg} {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.expert--sm {
  width: 100%;
  margin-bottom: 20px;

  @media #{$breakpoint-lg} {
    width: 50%;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0;
  }

  figure {
    position: relative;
    overflow: hidden;
    @media #{$breakpoint-md} {
      width: 20%;
    }
    @media #{$breakpoint-lg} {
      width: 31%;
      img {
        width: 120%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .expert__name {
    @media #{$breakpoint-md} {
      font-size: 22px;
      margin-bottom: 13px;
    }
    @media #{$breakpoint-lg} {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 6px;
    }
  }

  .expert__role {
    margin-bottom: 0;
    @media #{$breakpoint-md} {
      font-size: 11px;
    }
    @media #{$breakpoint-lg} {
      font-size: 14px;
    }
  }

  .expert__content {
    background: $grey;
    position: relative;
    padding: 24px 29px;

    @media #{$breakpoint-mobile} {
      margin-left: -20px;
      margin-right: -20px;
      min-height: 150px;
    }

    @media #{$breakpoint-md} {
      padding: 22px 29px;
      flex: 1;
    }

    @media #{$breakpoint-lg} {
      padding: 45px 50px 40px;
    }

  }

}
