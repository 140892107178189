/**
* Pixel2HTML - 4137
* HomePage
*/

.page--home {
  .site-content {
    padding-bottom: 7px;
    @media #{$breakpoint-md} {
      padding-bottom: 42px; // possibly should be 40px but 42px made a more precise fit
    }
  }

  // Hero text different size on homepage...

  .hero {

    &__content {
      @media #{$breakpoint-md} {
        margin-bottom: $hero-mb-tablet-home;
      }
      @media #{$breakpoint-xl} {
        margin-bottom: $hero-mb-desktop-home;
      }
    }
    &__title {
      @media #{$breakpoint-md} {
        font-size: $hero-title-size-tablet-home;
        margin-bottom: 12px;
      }
      @media #{$breakpoint-xl} {
        font-size: $hero-title-size-desktop;
      }
    }
    &__subtitle {
      @media #{$breakpoint-md} {
        line-height: 1.25;
      }
      @media #{$breakpoint-xl} {
        line-height: 1.5;
      }
    }
  }

  .hero--constrained {
    @media #{$breakpoint-md} {
      margin-bottom: 61px;
    }
    @media #{$breakpoint-xl} {
      margin-bottom: $hero-mb-desktop;
    }
  }
}
