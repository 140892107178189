.page--people {

  .section--top {
    padding-top: 40px;
    padding-bottom: 15px;
    @media #{$breakpoint-md} {
      padding-top: 60px;
      padding-bottom: 40px;
    }
    @media #{$breakpoint-xl} {
      padding-top: 80px;
      padding-bottom: 60px;
    }
  }

  .main-heading {
    margin-bottom: 26px; // Different to default
    @media #{$breakpoint-md} {
      margin-bottom: 55px;
    }
    @media #{$breakpoint-xl} {
      margin-bottom: 20px;
    }
  }

  .btn--load-more {
    margin-bottom: 80px;
  }

  .section-heading {
    @media #{$breakpoint-xl} {
      margin-bottom: 60px;
    }
  }

}


.page--people-detail {
  p {
    @media #{$breakpoint-md} {
      font-size: 17px;
    }
  }
  .section--top {
    padding-top: 20px;
    padding-bottom: 30px;
    @media #{$breakpoint-md} {
      padding-top: 50px;
      padding-bottom: 40px;
    }
    @media #{$breakpoint-lg} {
      padding-top: 42px; // 2px different to other people page..
      padding-bottom: 83px;
    }
  }
  .btn--back {
    margin-bottom: 7px;
  }
  .person__image {
    padding-bottom: 35px;

    @media #{$breakpoint-lg} {
      width: 75%;
    }
    @media #{$breakpoint-xl} {
      width: 100%;
    }
  }
  .sidebar__block {
    padding-bottom: 10px;

    @media #{$breakpoint-md} {
      width: 33.33%;
      float: left;
      padding-bottom: 0;
    }

    @media #{$breakpoint-lg} {
      width: 100%;
      float: none;
      margin-bottom: 32px;
    }

    .sideheading {
      margin-top: 0;
      margin-bottom: 19px;
      @media #{$breakpoint-lg} {
        margin-bottom: 13px;
      }
    }

    .list--sidebar {
      li {
        font-size: 12px;
        margin-bottom: 7px;
        @media #{$breakpoint-lg} {
          font-size: 17px;
          margin-bottom: 5px;
        }
      }
    }
  }
  .col--side {
    padding-top: 33px;
    @media #{$breakpoint-md} {
      padding-top: 52px;
    }
    @media #{$breakpoint-lg} {
      padding-top: 72px; // To align with content as per design.
    }
  }
}
