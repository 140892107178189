button,
input[type="button"],
input[type="reset"],
input[type="submit"] {

  -webkit-tap-highlight-color: rgba(0,0,0,0);

  &:hover {

  }

  &:active,
  &:focus {

  }
}


//
// Button
//

.btn {
  display: inline-block;
  height: $btn-height;
  line-height: $btn-height;
  border: 1px solid $btn-border;
  background: $white;
  font-size: $btn-font-size;
  color: $btn-color;
  cursor: pointer;
  font-weight: $btn-font-weight;
  padding: 0 5px;
  text-transform: uppercase;
  outline: 0;
  text-align: center;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;


  &--load-more {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    @media #{$breakpoint-md} {
      max-width: 180px;
    }
  }

  &--blue {
    background-color: $blue-pastel;
    border-color: $blue-pastel;
    color: $white;
  }

  &--md {
    width: 100%;
    @media #{$breakpoint-md} {
      max-width: 180px;
    }
  }

  &--back {
    font-size: 10px;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: $letter-spacing-10;
    display: inline-block;
    padding: 10px 0;
    color: rgba($dark, 0.4);
    border: 0;
    outline: 0;
    background: transparent;
    text-transform: uppercase;

    svg {
      width: 3px;
      height: 7px;
      fill: rgba(#302e2b, 0.4);
      margin-right: 5px;
    }
  }

  //
  // Hover State
  //

  &:hover {
    background-color: $dark;
    border-color: $dark;
    color: $white;
  }

  &--dark {
    background-color: $dark;
    color: $white;
    border-color: $dark;

    &:hover {
      color: $dark;
      border-color: $btn-border;
      background-color: $white;
    }
  }
}


//
// Hamburger Button
//

.mobile-nav-toggle {
  width: 30px;
  height: 20px;
  position: relative;
  border: 0;
  background: transparent;
  overflow: hidden;
  padding: 0;
  outline: 0;

  span {
    height: 2px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: $blue-pastel;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: $blue-pastel;
    left: 0;
    position: absolute;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

}


//
// Search Icon Button
//

.search-button {
  width: 15px;
  height: 15px;
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  svg {
    width: 100%;
    height: auto;
    fill: #858380;
  }
  &:hover {
    svg {
      fill: $blue-pastel;
    }
  }
}


//
// Text button
//

.text-button {
  text-transform: uppercase;
  color: $blue-pastel;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: $letter-spacing-10;

  &:hover {
    color: $dark;
  }
}
