//
// Main Heading
//

.main-heading {
  text-transform: uppercase;
  font-family: $condensed;
  letter-spacing: $letter-spacing-50;
  font-size: $main-heading-size-mobile;
  position: relative;
  line-height: 1.15;
  padding-top: 30px; // line height and :before size accounted (same distance for all breakpoints in design)
  margin-bottom: $main-heading-mb-mobile;

  @media #{$breakpoint-md} {
    font-size: $main-heading-size-tablet;
    margin-bottom: $main-heading-mb-tablet;
  }
  @media #{$breakpoint-xl} {
    font-size: $main-heading-size-desktop;
    margin-bottom: $main-heading-mb-desktop;
  }

  &--md {
    @media #{$breakpoint-xl} {
      margin-bottom: 20px;
    }
  }

  &:before {
    content: "";
    display: block;
    width: 60px;
    height: 8px;
    background-color: currentColor;
    position: absolute;
    top: 0;
    left: 0;
  }
}


//
// Italic Heading for sections
//

.section-heading {
  font-family: $georgia;
  font-style: italic;
  font-size: $section-heading-size-mobile;
  position: relative;
  margin-bottom: 46px;

  &:after {
    content: "";
    display: block;
    width: 60px;
    height: 8px;
    background: currentColor;
    margin-top: 14px;
  }

  @media #{$breakpoint-md} {
    font-size: $section-heading-size-tablet;
    margin-bottom: 74px;
    &:after {
      margin-top: 11px;
    }
  }
  @media #{$breakpoint-xl} {
    font-size: $section-heading-size-desktop;
    margin-bottom: 90px;
    &:after {
      width: 60px;
    }
  }

  &--blue {
    color: $blue-pastel;
  }

  &--sm {
    margin-bottom: 40px;
  }

}


//
// Subheading
//

.subheading {
  font-size: 20px;
  font-family: $georgia;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: $letter-spacing-10;
  margin-bottom: 24px;

  @media #{$breakpoint-md} {
    font-size: 24px;
  }

  &--blue {
    color: $blue-pastel;
  }
}


//
// Sidebar Heading
//

.sideheading {
  font-family: $georgia;
  font-style: italic;
  letter-spacing: $letter-spacing-25;
  color: rgba($dark, 0.4);
  font-size: 22px;
  margin-bottom: 18px;

  @media #{$breakpoint-lg} {
    font-size: 30px;
    margin-top: -6px; // To align with left side content
    margin-bottom: 10px;
  }

}


//
// Text colours
//

.text-blue {
  color: $blue;
}

.text-orange {
  color: $orange;
}

.text-purple {
  color: $purple;
}

.text-green {
  color: $green;
}
