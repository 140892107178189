.figure {
  padding-top: 4px;
  margin-bottom: 34px;

  @media #{$breakpoint-md} {
    padding-top: 8px;
    margin-bottom: 40px;
  }

  @media #{$breakpoint-lg} {
    padding-top: 8px;
    margin-bottom: 53px;
  }

  img {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }

  figcaption {
    display: block;
    font-family: $georgia;
    font-style: italic;
    color: rgba($dark, 0.4);
    font-size: 12px;
    padding-top: 7px;

    @media #{$breakpoint-md} {
      padding-top: 15px;
      font-size: 16px;
    }

  }

  &--pt0 {
    padding-top: 0;
  }

}
