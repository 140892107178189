.site-nav {
  a {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: $letter-spacing-145;
    text-transform: uppercase;
    margin-right: 70px;
    transition: color 0.2s ease-in-out;

    &:hover, &.selected {
      color: $blue-pastel;
    }

    &:last-of-type {
      margin-right: 55px;
    }
  }
}
