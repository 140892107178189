.container {
   // Sidebar Layout Small
  &-sidebar-sm {
    .col {
      &--main {
        width: 100%;
        @media #{$breakpoint-lg} {
          width: calc((6/12 * 100%) + (6.4 * 20px));
          padding-right: 20px;
        }
        @media #{$breakpoint-xl} {
          width: calc((7/12 * 100%) + (6.4 * 20px));
        }
      }

      &--side {
        width: 100%;
        margin-top: 35px;
        @media #{$breakpoint-lg} {
          margin-top: 0;
          padding-left: (1/12 * 100%);
          width: (4/12 * 100%);
        }
        @media #{$breakpoint-xl} {
          width: (3/12 * 100%);
        }
        .sidebar__row {
          padding-top: 34px;
          padding-bottom: 12px;
          border-top: 1px solid $sidebar-border;
          border-bottom: 1px solid $sidebar-border;
          @media #{$breakpoint-md} {
            padding-top: 53px;
            padding-bottom: 27px;
          }
          @media #{$breakpoint-lg} {
            padding: 0;
            border: 0;
          }
          &:not(:only-of-type) {
            &:last-of-type {
              border: 0;
            }
          }
        }
      }
    }
  }


  // Sidebar Layout Medium
  &-sidebar-md {
    @media #{$breakpoint-md} {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media #{$breakpoint-lg} {
      padding-left: 0;
      padding-right: 0;
    }

    .col {

      &--main {
        width: 100%;
        @media #{$breakpoint-lg} {
          width: (8/12) * 100%;
          padding-left: calc(((1/12) * 100%) + 2px); //2px for fine adjustment.
          padding-right: calc(((1/12) * 100%) + 2px); //2px for fine adjustment.
        }
      }

      &--side {
        width: 100%;
        border-top: 1px solid $sidebar-border;
        margin-top: 3px;
        @media #{$breakpoint-md} {
          margin-top: 42px;
        }
        @media #{$breakpoint-lg} {
          width: (4/12) * 100%;
          padding-top: 0;
          padding-left: 13px;
          margin-top: 0;
          border-top: 0;
        }
      }

    }
  }
}
