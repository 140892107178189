.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: $white;
  overflow-y: auto;

  &__content {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 70px 20px 0;
    max-width: 978px;

    @media #{$breakpoint-md} {
      padding: 110px 50px 0;
    }

    @media #{$breakpoint-lg} {
      padding-left: 0;
      padding-right: 0;
    }

  }

  &__inner {
    max-width: 785px;
    margin-left: auto;
    margin-right: auto;
  }

  &__close {
    position: absolute;
    top: 26px;
    right: 20px;
    width: 28px;
    height: 28px;
    display: block;
    background: transparent;
    border: 0;
    outline: 0;
    padding: 0;

    svg {
      width: 28px;
      height: 28px;
      polygon {
        fill: $dark;
      }
      &:hover {
        polygon {
          fill: $blue-pastel;
        }
      }
    }

    @media #{$breakpoint-md} {
      top: 51px; // to match design.
      right: 50px;
    }

  }

  &__type {
    font-family: $condensed;
    text-transform: uppercase;
    color: $dark;
    letter-spacing: $letter-spacing-25;
    font-size: 28px;
    line-height: 34px;
    position: relative;
    padding-top: 30px;
    margin-bottom: 47px;

    &:before {
      content: "";
      width: 60px;
      height: 8px;
      position: absolute;
      display: block;
      background: currentColor;
      top: 0;
      left: 0;
    }

    @media #{$breakpoint-md} {
      font-size: 45px;
      line-height: 1.1;
      padding-top: 32px;
      margin-bottom: 102px;
    }
  }

  &__title {
    font-size: 28px;
    line-height: 34px;
    font-family: $condensed;
    letter-spacing: $letter-spacing-25;
    color: $dark;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 43px;

    @media #{$breakpoint-md} {
      font-size: 38px;
      line-height: 1.1;
      margin-bottom: 25px;
    }
  }


  //
  // Search
  //

  &--search {
    .overlay__content {
      padding: 108px 17px 0;

      @media #{$breakpoint-md} {
        padding: 166px 50px 0;
      }

      @media #{$breakpoint-lg} {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .social-icons {
    margin-bottom: 50px;

    @media #{$breakpoint-mobile} {
      justify-content: space-between;
      li {
        margin-right: 0;
      }
    }

    @media #{$breakpoint-md} {
      margin-bottom: 76px;
    }

    svg {
      fill: $blue-pastel;
      polygon,
      path {
        fill: $blue-pastel;
      }

      &:hover {
        fill: $dark;
        polygon,
        path {
          fill: $dark;
        }
      }
    }
  }

}
