.lang-select {

  svg {
    width: 10px;
    height: 5px;
    display: inline-block;
    vertical-align: middle;
    fill: currentColor;
    polygon {
      fill: currentColor;
    }
    margin-right: 6px;
  }

  &__placeholder {
    font-size: 12px;
    font-weight: 500;
    color: rgba($dark, 0.5);
    letter-spacing: $letter-spacing-145;
  }

  &:hover {
    .lang-select__placeholder {
      color: $blue-pastel;
    }
  }

  &__button {
    padding: 0;
    background: none;
    border: 0;
    outline: 0;
    display: inline-block;
  }

  &__drop {
    padding: 0 0 10px;
    margin-top: 3px;
    background: $blue-pastel;
    position: absolute;
    top: 100%;
    right: 0;
    list-style: none;
    display: none;

    a {
      display: block;
      white-space: nowrap;
      font-weight: 500;
      font-size: 12px;
      text-align: right;
      line-height: 1.1;
      padding-left: 14px;
      padding-right: 14px;
      padding-top: 10px;
      color: #dad9d6;
      letter-spacing: $letter-spacing-145;
    }
  }

}
