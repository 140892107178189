.social-icons {
  list-style: none;
  display: flex;
  align-content: flex-end;
  justify-content: center;

  li {
    margin-right: 50px;
    height: 30px;

    @media #{$breakpoint-md} {
      height: 31px;
    }
    @media #{$breakpoint-xl} {
      height: 32px;
    }

    &:last-of-type {
      margin-right: 0;
    }

    a {
      display: block;
    }

    svg {
      vertical-align: middle;
    }
  }

  .icon {
    display: block;
    svg {
      width: 100%;
      height: auto;
    }
  }

  .icon-instagram {
    svg {
      width: 30px;
      height: 30px;
      @media #{$breakpoint-md} {
        width: 31px;
        height: 31px;
      }
      @media #{$breakpoint-xl} {
        width: 32px;
        height: 32px;
      }
    }
  }

  .icon-twitter {
    padding-top: 6px;
    svg {
      width: 28px;
      height: 24px;
      @media #{$breakpoint-md} {
        width: 29px;
        height: 25px;
      }
      @media #{$breakpoint-xl} {
        width: 30px;
        height: 26px;
      }
    }
  }

  .icon-linkedin {
    padding-top: 3px;
    svg {
      width: 28px;
      height: 28px;
      @media #{$breakpoint-md} {
        width: 29px;
        height: 29px;
      }
      @media #{$breakpoint-xl} {
        width: 30px;
        height: 30px;
      }
    }
  }

  .icon-googleplus {
    padding-top: 1px;
    svg {
      width: 36px;
      height: 36px;
      @media #{$breakpoint-md} {
        width: 37px;
        height: 37px;
      }
      @media #{$breakpoint-xl} {
        width: 38px;
        height: 38px;
      }
    }
  }

  .icon-facebook {
    padding-top: 2px;
    svg {
      width: 28px;
      height: 28px;
      @media #{$breakpoint-md} {
        width: 29px;
        height: 29px;
      }
      @media #{$breakpoint-xl} {
        width: 30px;
        height: 30px;
      }
    }
  }

  .icon-email {
    padding-top: 11px;
    svg {
      width: 28px;
      height: 21px;
      @media #{$breakpoint-md} {
        width: 29px;
        height: 22px;
      }
      @media #{$breakpoint-xl} {
        width: 30px;
        height: 23px;
      }
    }
  }

  &--sm {
    li {
      height: 20px;
      margin-right: 15px;
    }
    .icon {
      width: 20px;
      height: 20px;
      svg {
        fill: $blue-pastel;
      }

      &:hover {
        opacity: 1;
        svg {
          fill: $dark;
        }
      }

      &-twitter {
        padding-top: 0;
        svg {
          width: 18px;
          height: 15px;
        }
      }
      &-linkedin {
        padding-top: 1px;
        svg {
          width: 15px;
          height: 15px;
        }
      }
      &-email {
        padding-top: 1px;
        svg {
          width: 20px;
          height: 13px;
        }
      }
    }
  }

}
