.mobile-nav {
  display: none;

  @media #{$breakpoint-xl} {
    display: none;
  }

  // Sidebar container
  &__wrap {
    width: $mobile-nav-width-mobile;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background: $white;
    display: none;
    left: -($mobile-nav-width-mobile + 10);
    transition: left 0.3s ease-in-out;
    box-shadow: 0 1px 5px rgba(5,6,6,0.25);
    z-index: -1;
    overflow: hidden;
    @media #{$breakpoint-md} {
      width: $mobile-nav-width-tablet;
      left: -$mobile-nav-width-tablet;
    }
  }

  // Nav Panel (for multi panels)
  &__panel {
    width: 100%;
    height: 100%;
    padding: 75px 40px 0;
    overflow-y: auto;
    @media #{$breakpoint-md} {
      padding: 85px 50px 0;
    }

    &--top {
      transform: translateX(0);
      transition: transform 0.3s ease-in-out;
      &.hide-panel {
        transform: translateX(-$mobile-nav-width-mobile);
        @media #{$breakpoint-md} {
          transform: translateX(-$mobile-nav-width-tablet);
        }
      }
    }

    &--sub {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: $white;
      transition: transform 0.3s 0.2s ease-in-out;
      transform: translateX(($mobile-nav-width-mobile + 10));
      @media #{$breakpoint-md} {
        transform: translateX(($mobile-nav-width-tablet + 10));
      }
      &.show-panel {
        transform: translateX(0);
      }
    }

  }

  // Nav Link Items
  &__list {
    list-style: none;
    li {
      font-size: 26px;
      text-transform: uppercase;
      margin-bottom: 20px; // line height accounted
      line-height: 1.1;
      font-family: $condensed;
      letter-spacing: $letter-spacing-50;

      @media #{$breakpoint-md} {
        font-size: 45px;
        margin-bottom: 30px; // line height accounted
      }

      &.meta {
        color: $meta;
      }

      a {
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        &:hover,
        &:focus,
        &:active {
          color: $blue-pastel;
        }
      }
    }
  }

  // Close Button
  &__close {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 100;
    background: transparent;
    padding: 0;
    border: 0;
    outline: 0;
    svg {
      fill: $dark;
      width: 24px;
      height: 24px;
      polygon {
        fill: $dark;
      }
    }
    &:hover {
      svg {
        polygon {
          fill: $blue-pastel;
        }
      }
    }
  }

  // Back Button
  &__back {
    position: absolute;
    left: 20px;
    top: 31px; // -10px to account for button padding for touch target
  }

}

//

.has-sub {
  svg {
    width: 8px;
    height: 15px;
    fill: #b8b8b7;
    margin-left: 4px;
    position: relative;
    top: -2px;

    @media #{$breakpoint-md} {
      width: 14px;
      height: 26px;
    }
  }
}



// Apply to div's you want to move when you open sidebar

.will-shove {
  left: 0;
  transition: left 0.3s ease-in-out;
}


//
// Sidebar Open State
//

.sidebar-open {
  @media #{$breakpoint-not-desktop} {
    overflow: hidden;
    body {
      overflow: hidden;
    }
  }
  .will-shove {
    left: $mobile-nav-width-mobile;

    @media #{$breakpoint-md} {
      left: $mobile-nav-width-tablet;
    }

    // Cancel transforms if resizing window to desktop size
    @media #{$breakpoint-xl} {
      left: 0;
    }

  }
  .mobile-nav {
    display: block;
  }

  .mobile-nav__wrap {
    left: 0;
    z-index: 1000;
    opacity: 1;
  }

}
