@font-face {
  font-family: UniverseUC;
  font-weight: normal;
  src: url("./../fonts/UniversLTStd-Ultra-Condensed.eot");
  src: url("./../fonts/UniversLTStd-Ultra-Condensed.woff") format('woff'),
       url("./../fonts/UniversLTStd-Ultra-Condensed.otf") format("opentype");
}

@font-face {
  font-family: Gotham;
  font-weight: normal;
  src: url("./../fonts/Gotham-Book.eot");
  src: url("./../fonts/Gotham-Book.woff") format("woff"),
       url("./../fonts/Gotham-Book.otf") format("opentype");
}

@font-face {
  font-family: Gotham;
  font-weight: 500;
  src: url("./../fonts/Gotham-Medium.eot");
  src: url("./../fonts/Gotham-Medium.woff") format("woff"),
       url("./../fonts/Gotham-Medium.otf") format("opentype");
}
