.team {
  //
  // Layout
  //

  &__grid {
    margin-left: -$space-2;
    margin-right: -$space-2;
    margin-bottom: 38px;
    @media #{$breakpoint-md} {
      margin-left: -$space-1;
      margin-right: -$space-1;
    }
    list-style: none;

    > li {
      float: left;
      margin-bottom: $space-2;
      @media #{$breakpoint-md} {
        padding-left: $space-1;
        padding-right: $space-1;
        &:nth-child(3n+1) {
          clear: left;
        }
      }
    }
  }

  //
  // Member
  //

  &-member {

    // On mobile only show first 6 members
    @media #{$breakpoint-mobile} {
      &:nth-child(n+7) {
        display: none;
      }
    }

    &__image {
      padding: 0;
      position: relative;
      > img {
        width: 100%;
        height: auto;
        vertical-align: middle;
      }
      &:hover,
      &:focus {
        .team-member__overlay {
          opacity: 1;
        }
      }
    }

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      opacity: 0;
      outline: 0;
      will-change: opacity;
      background-color: rgba($dark, 0.95);
      transition: opacity 0.3s ease-in-out;
      font-family: $georgia;
      font-style: italic;
    }

    .overlay-content {
      display: block;
      text-align: center;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      padding-left: 20px;
      padding-right: 20px;
      line-height: 1.28;
    }

    &__name {
      color: $white;
      margin-bottom: 0;
      font-size: 28px;
      line-height: 40px;
      letter-spacing: $letter-spacing-50;
      @media #{$breakpoint-md} {
        font-size: 18px;
        line-height: 24px;
      }
      @media #{$breakpoint-lg} {
        font-size: 28px;
        line-height: 1.28;
        margin-bottom: 0;
      }
    }

    &__role {
      color: $blue-pastel;
      font-size: 16px;
      padding-top: 10px;
      display: block;
      letter-spacing: $letter-spacing-50;
      @media #{$breakpoint-md} {
        font-size: 12px;
      }
      @media #{$breakpoint-lg} {
        font-size: 16px;
      }
    }

    // Hidden members
    &--load {
      display: none;
    }

  }

}
