.article-grid {
  margin-bottom: 52px;
  margin-left: -20px;
  width: calc(100% + 40px);
  margin-right: -20px;
  // max-width: $container-max-width;

  @media #{$breakpoint-md} {
    display: flex;
    flex: 1;
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 64px;
    > .article-grid__col {
      display: flex;
    }
  }
  @media #{$breakpoint-xl} {
    margin-bottom: 107px;
  }

  &__col {
    @media #{$breakpoint-md} {
      padding-left: $space-1;
      padding-right: $space-1;
    }
  }

  &__row {
    @media #{$breakpoint-md} {
      display: flex;
      &--dual {
        margin-left: -$space-1;
        margin-right: -$space-1;
        .article-preview {
          padding-left: $space-1;
          padding-right: $space-1;
        }
      }
    }
  }

  &__load {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  &--top {
    margin-bottom: 20px;
  }

  .article-preview {
    width: 100%;
    margin-bottom: 20px;

    @media #{$breakpoint-md} {
      width: 50%;
      display: flex;
      margin-bottom: 0;
    }

    &--featured {
      width: 100%;
      margin-bottom: 20px;
      align-items: center;

      .article-preview__content__wrap {
        z-index: 5;
        padding: 0 20px;
        position: relative;

        @media #{$breakpoint-md} {
          width: 65.5%;
          padding: 10px 0;
        }
        @media #{$breakpoint-lg} {
          width: 61.5%;
          padding-top: 30px;
          padding-bottom: 30px;
        }

        .article-preview__content {
          min-height: 292px;
          position: relative;
          @media #{$breakpoint-md} {
            padding-right: 80px;
            min-height: 0;
          }
          @media #{$breakpoint-xl} {
            padding-right: 130px; // Was 150px
          }
        }
      }

      &__image {
        width: 100%;
        height: 320px;
        overflow: hidden;
        position: relative;
        margin-top: -50px;

        @media #{$breakpoint-md} {
          width: calc(50% - 10px);
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          margin-top: 0;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-image: url('../images/overlay-lines@2x.png');
          background-repeat: repeat;
          background-size: 5px 5px;
        }

        img {
          width: 130%;
          height: auto;
          position: relative;
          vertical-align: middle;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          @media #{$breakpoint-lg} {
            width: 140%;
          }
          @media #{$breakpoint-xl} {
            width: 120%;
          }
        }
      }

      // reversed
      &.reversed {
        justify-content: flex-end;

        .article-preview {

          &--featured {
            &__image {
              @media #{$breakpoint-md} {
                right: auto;
                left: 10px;
              }
            }
          }
        }
      }

    }

    &--tall {
      width: 100%;
      .article-preview__content {
        @media #{$breakpoint-lg} {
          padding-left: 45px;
          padding-right: 45px; // default is 50px but there was a text wrap issue.
        }
      }
      .article-preview__author {
        margin-bottom: 45px;
      }
    }
  }
}



.article-preview {
  position: relative;
  width: 100%;

  &__content {
    background-color: $grey;
    padding: 26px 25px 65px;
    position: relative;
    min-height: 320px;
    display: block;
    @media #{$breakpoint-md} {
      min-height: 231px;
      padding: 31px 30px 55px;
    }
    @media #{$breakpoint-lg} {
      min-height: 377px;
      padding: 47px 50px 90px; // -3px to account for line-height
      padding: 47px 45px 90px; // was 50px side padding, changed to 45px to match thinking-index page grid
    }
  }

  &__type {
    font-size: $article-preview-type-size-mobile;
    color: $blue-pastel;
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: $letter-spacing-150;
    margin-bottom: 10px;
    @media #{$breakpoint-md} {
      font-size: $article-preview-type-size-tablet;
      margin-bottom: 13px;
    }
    @media #{$breakpoint-lg} {
      font-size: $article-preview-type-size-desktop;
      margin-bottom: 25px;
    }
    &:after {
      content: "_";
      display: inline;
    }
  }

  &__title {
    font-size: $article-preview-title-size-mobile;
    font-family: $georgia;
    font-style: italic;
    letter-spacing: $letter-spacing-10;
    margin-bottom: 3px; // line height restrictions
    @media #{$breakpoint-md} {
      font-size: $article-preview-title-size-tablet;
      margin-bottom: 7px; // line height restrictions
    }
    @media #{$breakpoint-lg} {
      font-size: $article-preview-title-size-desktop;
      margin-bottom: 13px; // line height restrictions
    }
  }

  &__author {
    font-size: $article-preview-author-size-mobile;
    color: rgba($dark, 0.4);
    display: block;
    font-weight: 500;
    letter-spacing: $letter-spacing-10;
    text-transform: uppercase;
    @media #{$breakpoint-md} {
      font-size: $article-preview-author-size-tablet;
    }
    @media #{$breakpoint-lg} {
      font-size: $article-preview-author-size-desktop;
    }
  }

  &__category {
    font-size: $article-preview-category-size-mobile;
    color: $blue-pastel;
    display: block;
    letter-spacing: $letter-spacing-10;
    font-family: $georgia;
    font-style: italic;
    position: absolute;
    bottom: 25px;
    left: 25px;
    @media #{$breakpoint-md} {
      font-size: $article-preview-category-size-tablet;
      bottom: 29px; // Accounting for line height
      left: 30px;
    }
    @media #{$breakpoint-lg} {
      font-size: $article-preview-category-size-desktop;
      bottom: 50px - 6; // Accounting for line height
      left: 50px;
    }
  }

  &__excerpt {
    line-height: 1.8;
    font-family: $georgia;
    font-style: italic;
    font-size: $article-preview-excerpt-size-mobile;
    display: none;
    @media #{$breakpoint-md} {
      font-size: $article-preview-excerpt-size-tablet;
      display: block;
    }
    @media #{$breakpoint-lg} {
      font-size: $article-preview-excerpt-size-desktop;
    }
  }

  &__content {
    &:hover {
      background-color: $dark;
      .article-preview__title,
      .article-preview__excerpt {
        color: $white;
      }
      .article-preview__author {
        color: $dark-grey;
      }
    }
  }

  &-load {
    &:not(.loaded) {
      @media #{$breakpoint-mobile} {
        display: none!important;
      }
    }
  }

}



//
// Article Grid Variants
//

.article-grid {

  &--rows {
    margin-bottom: 47px;

    @media #{$breakpoint-md} {
      width: calc(100% + 20px); // right side alignment issue.
      margin-left: 0;
    }

    @media #{$breakpoint-md} {
      flex-wrap: wrap;
      margin-bottom: 28px;
    }

    @media #{$breakpoint-lg} {
      margin-bottom: 87px;
      width: calc(100% + 20px);
      margin-left: 0;
      margin-right: 0;
    }

    .article-grid__row {
      width: 100%;
      margin-bottom: 20px;
    }

    .article-preview {
      @media #{$breakpoint-md} {
        width: 33.33%;
        &--featured {
          width: 66.66%;
          margin-bottom: 0;
          &__image {
            width: calc(50% - 20px);
            right: 10px;
          }
        }
      }
      @media #{$breakpoint-md} {
        &__content {
          min-height: 210px!important;
        }
      }
      @media #{$breakpoint-lg} {
        &__content {
          min-height: 318px!important;
        }
      }
      @media #{$breakpoint-mobile} {
        &--featured {
          display: flex;
          flex-direction: column;

          &__image {
            order: 1;
            margin-top: 0;
            margin-bottom: -50px;
          }
          .article-preview__content__wrap {
            order: 2;
          }
        }
      }
    }

  }

}


//
// Article in Hero
//

.article-preview--hero {

  @media #{$breakpoint-md} {
    width: 81%;
  }

  .article-preview {

    &__type {
      font-size: 11px;
      font-weight: 500;
      color: $white;

      @media #{$breakpoint-md} {
        margin-bottom: 20px;
      }

      @media #{$breakpoint-lg} {
        font-size: 13px;
        margin-bottom: 25px;
      }
    }

    &__title {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 10px;

      @media #{$breakpoint-md} {
        font-size: 23px;
        line-height: 28px;
        margin-bottom: 17px;
      }

      @media #{$breakpoint-lg} {
        font-size: 33px;
        line-height: 42px;
        margin-bottom: 14px;
      }
    }

    &__author {
      color: rgba($white, 0.4);
      font-size: 12px;
      margin-bottom: 53px;
      @media #{$breakpoint-md} {
        font-size: 11px;
        margin-bottom: 32px;
      }
      @media #{$breakpoint-lg} {
        font-size: 14px;
        margin-bottom: 50px;
      }
    }

    &__category {
      color: $white;
      position: relative;
      bottom: auto;
      left: auto;
    }

    &__content {
      padding: 0;
      background-color: transparent;
      min-height: 0;

      &:hover {
        .article-preview {
          &__author {
            color: rgba($white, 0.4);
          }
        }
      }

    }

  }

}


//
// Page article author
//

.article {
  &__header {
    margin-bottom: $main-heading-mb-mobile;
    @media #{$breakpoint-md} {
      margin-bottom: 34px;
    }
    @media #{$breakpoint-xl} {
      margin-bottom: 20px;
    }

    .main-heading {
      margin-bottom: 4px;
      @media #{$breakpoint-md} {
        margin-bottom: 2px;
      }
      // @media #{$breakpoint-lg} {
      //   width: 72%;
      // }
    }

  }
  &__author {
    font-family: $georgia;
    font-style: italic;
    font-size: 16px;
    color: rgba($dark, 0.4);
  }
}


//
// Article List
//

.article-list {

  &__wrap {
    padding-bottom: 41px;

    @media #{$breakpoint-md} {
      padding-bottom: 59px;
    }

    @media #{$breakpoint-lg} {
      padding-bottom: 70px;
    }
  }

  &__item {
    border-bottom: 1px solid $border;
    padding-top: 54px;
    padding-bottom: 33px;

    @media #{$breakpoint-md} {
      padding-bottom: 35px;
      padding-top: 55px;
    }

    @media #{$breakpoint-lg} {
      padding-bottom: 33px;
    }

    &:hover {
      border-color: $blue-pastel;
      .article-list {
        &__type {
          color: rgba($dark, 0.4);
        }
        &__title {
          color: $blue-pastel;
        }
      }
    }
  }

  &__type {
    font-size: 11px;
    font-weight: 500;
    color: $blue-pastel;
    letter-spacing: $letter-spacing-150;
    text-transform: uppercase;
    display: block;
    margin-bottom: 39px;


    &:after {
      content: "_";
      display: inline;
    }

    @media #{$breakpoint-md} {
      margin-bottom: 34px;
    }

    @media #{$breakpoint-lg} {
      font-size: 14px;
    }
  }

  &__title {
    font-size: 28px;
    line-height: 34px;
    letter-spacing: $letter-spacing-25;
    font-family: $condensed;
    text-transform: uppercase;
    margin-bottom: 14px;


    @media #{$breakpoint-md} {
      font-size: 35px;
      line-height: 1.1;
    }

    @media #{$breakpoint-lg} {
      font-size: 38px;
      margin-bottom: 11px;
    }
  }

  &__meta {
    font-family: $georgia;
    font-style: italic;
    color: #bebebe;
    font-size: 13px;
    margin-bottom: 1px;


    @media #{$breakpoint-lg} {
      font-size: 14px;
    }
  }

  &__category {
    font-family: $georgia;
    font-style: italic;
    color: $blue-pastel;
    font-size: 13px;

    @media #{$breakpoint-lg} {
      font-size: 14px;
    }
  }

}
