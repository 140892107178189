//
// Work Index
//

.page--work {

  .filter__title {
    @media #{$breakpoint-md} {
      margin-bottom: 68px;
    }
    @media #{$breakpoint-lg} {
      margin-bottom: 90px;
    }
  }

  .hero--constrained {
    @media #{$breakpoint-xl} {
      margin-bottom: 68px; // different to default value.
    }
  }

  .section--top {
    padding-top: 0;
    padding-bottom: 0;

    .section-heading { // different to global values.
      margin-bottom: 40px;
      @media #{$breakpoint-md} {
        margin-bottom: 30px;
      }
    }
  }
  .btn--load-more {
    @media #{$breakpoint-mobile} {
      margin-bottom: 35px;
    }
  }
}


//
// Work detail (with sidebar)
//

.page--work-detail {
  .section--top {
    padding-top: 28px;
    padding-bottom: 30px;
    @media #{$breakpoint-md} {
      padding-top: 20px;
      padding-bottom: 40px;
    }
    @media #{$breakpoint-lg} {
      padding-top: 41px; // 3px different to other people page..
      padding-bottom: 90px;
    }
  }
  .btn--back {
    margin-bottom: 11px;
    @media #{$breakpoint-md} {
      margin-bottom: 8px;
    }
  }

  // Sidebar
  .sidebar__row {
    padding-top: 50px; // Different than default
    padding-bottom: 4px;


    &:first-of-type {
      @media #{$breakpoint-lg} {
        margin-top: -6px; // To align with the paragraph text.
      }
    }

    &:last-of-type {
      padding-top: 27px;
      padding-bottom: 20px;

      @media #{$breakpoint-md} {
        padding-top: 51px;
        padding-bottom: 28px;
      }
      @media #{$breakpoint-lg} {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .sidebar__block {
    padding-bottom: 10px;

    @media #{$breakpoint-md} {
      width: 25%;
      float: left;
      padding-bottom: 0;
    }

    @media #{$breakpoint-lg} {
      width: 100%;
      float: none;
      margin-bottom: 26px;
    }

    .sideheading {
      margin-top: 0;
      margin-bottom: 12px;

      @media #{$breakpoint-md} {
        margin-bottom: 19px;
      }
      @media #{$breakpoint-lg} {
        margin-bottom: 9px;
      }
    }

    .list--sidebar {
      li {
        font-size: 12px;
        margin-bottom: 7px;
        @media #{$breakpoint-lg} {
          font-size: 17px;
          margin-bottom: 5px;
          white-space: nowrap; // Hack to let text overflow the sidebar like the design..
        }
      }
    }
  }

  .btn-group--stacked {
    padding-top: 10px;
  }

  // Related
  .section--related-work {
    padding-top: 43px; // Spacing adjustment from other instances of this section..

    @media #{$breakpoint-md} {
      padding-top: 60px;
      padding-bottom: 108px;
    }

    @media #{$breakpoint-xl} {
      padding-top: 98px;
    }

    header {
      .btn {
        margin-bottom: 20px; // Adjusted from default to match design.
      }
    }
  }

  .related-work__wrap {
    margin-bottom: 15px; // Adjusted from default to match design..
  }

  // Expert

  .expert__wrap {
    .section-heading {
      @media #{$breakpoint-xl} {
        margin-bottom: 45px;
      }
    }
  }

  .article__header {
    @media #{$breakpoint-lg} {
      width: calc((7/12 * 100%) + (6.4 * 20px));
      padding-right: 20px;
    }
  }

}


//
// Work detail (full width)
//

.page--work-detail-full {
  .section--top {
    padding-top: 28px;
    padding-bottom: 30px;
    @media #{$breakpoint-md} {
      padding-top: 20px;
      padding-bottom: 33px;
    }
    @media #{$breakpoint-lg} {
      padding-top: 41px; // 3px different to other people page..
      padding-bottom: 68px;
    }
  }
  .btn--back {
    margin-bottom: 11px;
    @media #{$breakpoint-md} {
      margin-bottom: 8px;
    }
  }

  // Related
  .section--related-work {
    padding-top: 43px; // Spacing adjustment from other instances of this section..

    @media #{$breakpoint-md} {
      padding-top: 60px;
      padding-bottom: 108px;
    }

    @media #{$breakpoint-xl} {
      padding-top: 98px;
    }

    header {
      .btn {
        margin-bottom: 20px; // Adjusted from default to match design.
      }
    }
  }

  .related-work__wrap {
    margin-bottom: 15px; // Adjusted from default to match design..
  }

  // Expert

  .expert__wrap {
    .section-heading {
      @media #{$breakpoint-xl} {
        margin-bottom: 45px;
      }
    }
  }

  // Figure's (different spacing on this page to other pages)
  .figure {
    padding-top: 6px;
    margin-bottom: 18px;

    @media #{$breakpoint-md} {
      padding-top: 26px;
      margin-bottom: 40px;
    }

    @media #{$breakpoint-lg} {
      padding-top: 42px;
      margin-bottom: 34px;
    }

    &--pt0 {
      padding-top: 0;
    }
  }

  // Blockquote

  blockquote {
    &:before {
      top: 22px;
      @media #{$breakpoint-md} {
        top: 19px;
      }
      @media #{$breakpoint-lg} {
        top: 41px;
      }
    }

    &.bq-featured {
      padding-top: 47px;
      margin-bottom: 51px;
      @media #{$breakpoint-md} {
        padding-top: 45px;
        margin-bottom: 27px;
      }
      @media #{$breakpoint-lg} {
        padding-top: 67px;
        margin-bottom: 36px;
      }
    }

    &.bq-source {
      margin-top: 32px;
    }
  }

  .featured-col {
    &:last-of-type {
      @media #{$breakpoint-lg} {
        padding-top: 35px;
      }
    }
  }


  // Footer (sidebar on the non full width page)
  // Sidebar
  .sidebar__row {
    padding-top: 50px; // Different than default
    padding-bottom: 4px;
    border-top: 1px solid $sidebar-border;
    border-bottom: 1px solid $sidebar-border;

    @media #{$breakpoint-md} {
      padding-top: 51px;
      padding-bottom: 40px;
    }

    @media #{$breakpoint-lg} {
      padding-top: 53px;
      padding-bottom: 34px;
    }

    &:last-of-type {
      border: 0;
    }

    &:last-of-type {
      padding-top: 27px;
      padding-bottom: 20px;

      @media #{$breakpoint-md} {
        padding-top: 51px;
        padding-bottom: 35px;
      }
    }
  }

  .sidebar__block {
    padding-bottom: 10px;

    @media #{$breakpoint-md} {
      width: 25%;
      float: left;
      padding-bottom: 0;
    }

    .sideheading {
      margin-top: 0;
      margin-bottom: 12px;

      @media #{$breakpoint-md} {
        margin-bottom: 13px;
      }
      @media #{$breakpoint-lg} {
        margin-bottom: 9px;
      }
    }

    .list--sidebar {
      li {
        font-size: 12px;
        margin-bottom: 7px;
        @media #{$breakpoint-lg} {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .btn-group__wrap {
    @media #{$breakpoint-lg} {
      text-align: center;
    }
  }

  .btn-group--stacked {
    padding-top: 10px;
    .btn {
      @media #{$breakpoint-lg} {
        margin-right: 20px;
        margin-bottom: 0;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }


  // Article Footer
  .article__footer {
    padding-top: 31px;
    @media #{$breakpoint-lg} {
      padding-top: 48px;
    }
  }

}
