.person {
  &__header {
    margin-bottom: 38px;
    @media #{$breakpoint-md} {
      margin-bottom: 30px;
    }
    @media #{$breakpoint-lg} {
      margin-bottom: 32px; // fit better than 30px
    }

    .main-heading {
      margin-bottom: 7px;
      @media #{$breakpoint-md} {
        margin-bottom: 0;
      }
    }
  }

  &__role {
    font-size: 16px;
    margin-right: 20px;
    font-family: $georgia;
    font-style: italic;
    color: rgba($dark, 0.4);
    margin-bottom: 10px;
    @media #{$breakpoint-md} {
      font-size: 20px;
      margin-bottom: 0;
    }
  }

  &__social {
    .social-icons {
      justify-content: flex-start;
      a {
        opacity: 0.6;
      }
    }
  }

  &__role,
  &__social {
    ul {
      margin-bottom: 0;
    }
    @media #{$breakpoint-md} {
      display: inline-block;
      vertical-align: middle;
      ul {
        margin-top: -2px; // alignment tweak
      }
    }
  }

  &__image {
    display: none;
    @media #{$breakpoint-lg} {
      display: block;
    }
    img {
      max-width: 100%;
      height: auto;
      vertical-align: middle;
    }
    &--mobile {
      display: block;
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: 33px;

      @media #{$breakpoint-md} {
        width: 100%;
        max-width: 381px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 70px;
      }
      @media #{$breakpoint-lg} {
        display: none;
      }
      img {
        width: 100%;
        height: auto;
        vertical-align: middle;
      }
    }
  }
}
